<template>
<label
    class="checkbox w-6 h-6 2xl:w-8 2xl:h-8"
    :class="{'checkbox--checked': modelValue}"
>
    <checkboxIcon class="icon" />
    <input
        class="hidden"
        type="checkbox"
        v-model="modelValue"
        @change="select"
    >
</label>
</template>

<script>
import checkboxIcon from "@/assets/icons/sc-journey/checkbox.svg";

export default {
    name: 'Checkbox',
    props: {
        value: {
            type: Boolean,
            required: true,
        }
    },
    components: {
        checkboxIcon,
    },
    data() {
        return {
            modelValue: false,
        }
    },
    computed: {
        // modelValue: {
        //     get() {
        //         return this.value;
        //     },
        //     set(value) {
        //         this.$emit('change', value);
        //     }
        // }
    },
    created() {
        this.modelValue = this.value;
    },
    methods: {
        select(e) {
            this.$emit('change', e);
            
        }
    },
}
</script>

<style lang="scss" scoped>
.checkbox {
    box-sizing: border-box;
    border-radius: 8px;
    border: 2px solid #0D69D5;
    background-color: #fff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .icon {
        display: none;
        width: 65%;
        fill: #0D69D5;
    }
    &--checked {
        .icon {
            display: initial;
        }
    }
}
</style>